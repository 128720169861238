<template>
  <div class="bg-gray-200">
    <div class="container mx-auto grid grid-cols-2 w-full">
      <div class="py-2 text-md">
        <router-link to="/" class="text-green hover:underline">Dashboard</router-link>
        <span class="text-gray-400"> / </span>
        <router-link to="/trips" class="text-green hover:underline">Trips</router-link>
        <span class="text-gray-400"> / </span>
        <span class="font-bold">Trip {{ parseDate(trip.start_date) }}</span>
      </div>
    </div>
  </div>
  <div class="container mx-auto py-5" v-if="!loadingTrip">
    <div class="grid grid-cols-3 xl:grid-cols-4">
      <div class="col-span-3 grid grid-cols-3">
        <div class="shadow col-span-1 bg-gray-50 text-center px-4 pt-2 pb-10">
          <div class="text-xs flex text-gray-400">
            <span >Created {{ parseDate(trip.created_at) }}</span>
            <span class="text-right flex-grow">Updated {{ parseDate(trip.updated_at) }}</span>
          </div>
          <span class="block text-gray-400 mt-10">Trip Report</span>
          <h2 class="font-bold text-3xl">{{ parseDate(trip.start_date) }}</h2>
          <span class="block mt-10 font-bold">{{ trip.rep_company.name }}</span>
        </div>
        <div class="shadow ml-5 bg-gray-100 col-span-2">
          <div class="text-sm">
            <div class="px-5 py-2 flex bg-gray-50">
              <span class="w-3/12">Outbound Flight</span>
              <span class="w-9/12 font-bold">{{ trip.outbound_airline }}
                <span v-if="trip.outbound_flight_number">({{ trip.outbound_flight_number }})</span>
              </span>
            </div>
            <div class="px-5 py-2 flex">
              <span class="w-3/12">Outbound Departure Time</span>
              <span class="w-9/12 font-bold">{{ parseDate(trip.outbound_departure_time) }} {{ parseTime(trip.outbound_departure_time) }}</span>
            </div>
            <div class="px-5 py-2 flex bg-gray-50">
              <span class="w-3/12">Outbound Arrival Time</span>
              <span class="w-9/12 font-bold">{{ parseDate(trip.outbound_arrival_time) }} {{ parseTime(trip.outbound_arrival_time) }}</span>
            </div>
            <div class="px-5 py-2 flex">
              <span class="w-3/12">Inbound Flight</span>
              <span class="w-9/12 font-bold">{{ trip.inbound_airline }}
                <span v-if="trip.inbound_flight_number">({{ trip.inbound_flight_number }})</span>
              </span>
            </div>
            <div class="px-5 py-2 flex bg-gray-50">
              <span class="w-3/12">Inbound Departure Time</span>
              <span class="w-9/12 font-bold">{{ parseDate(trip.inbound_departure_time) }} {{ parseTime(trip.inbound_departure_time) }}</span>
            </div>
            <div class="px-5 py-2 flex">
              <span class="w-3/12">Inbound Arrival Time</span>
              <span class="w-9/12 font-bold">{{ parseDate(trip.inbound_arrival_time) }} {{ parseTime(trip.inbound_arrival_time) }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="shadow bg-gray-100 mt-5 col-span-3">
          <div class="px-5 py-2" :class="{'bg-gray-50':key % 2}" v-for="(call, key) in trip.sales_calls" v-bind:key="call.id">
            <div class="flex w-full">
              <span class="mr-5 flex-grow">
                <span class="font-bold">
                  <router-link class="text-green hover:underline" :to="`/sales-calls/${call.id}`">{{ call.company_name }}</router-link>
                  <span v-if="call.title" class="font-normal"> ({{ call.title }})</span>
                </span>
                <span class="block text-sm text-gray-400">Start: {{ parseDate(call.start_time) }} {{ parseTime(call.start_time) }}</span>
              </span>
              <span class="text-center mx-10">
                <span class="font-bold">{{ call.action_items.length }}</span>
                <span class="block text-xs text-gray-400">Action Items</span>
              </span>
              <span class="text-center mx-10">
                <span class="font-bold">{{ call.attendees.length }}</span>
                <span class="block text-xs text-gray-400">Attendees</span>
              </span>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  data: () => {
    return {
      id: null,
      showChangeStatusModal: false,
      showAddCommentModal: false,
      comment: "",
      loadingTrip: true,
      status: null,
    }
  },
  computed: {
    ...mapGetters({
        trip: 'trips/details'
      }),
  },

  created: function () {
    this.loadTrip();
  },

  methods: {
    parseDate(date) {
			let d = new Date(date);
			return d.toLocaleDateString();
		},

    parseTime(date) {
			let d = new Date(date);
			return d.toLocaleTimeString();
		},

    async loadTrip() {
      this.loadingTrip = true;
      await this.$store.dispatch('trips/loadDetails', this.$route.params.id);
      document.title = 'MLE Rep Center | Trip '+ this.trip.start;
      this.loadingTrip = false;
    },
  }
}
</script>